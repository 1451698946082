import { FormValues } from './types';
import { optionValues } from '../../../form/select';
import { createValidation, Rules } from '../../../../../validation';

const rulesHomeOrStudent: Rules<FormValues> = {
    my_role: ['required'],
    first_name: ['required'],
    last_name: ['required'],
    email: ['required'],
};

const rulesEducator: Rules<FormValues> = {
    my_role: ['required'],
    my_role_description: ['required'],
    first_name: ['required'],
    last_name: ['required'],
    email: ['required'],
    district: ['required'],
    school: ['required'],
    programs_evaluation_goal_option: ['required'],
};

const rulesCallback = (values: FormValues) => {
    if (values['my_role'] === optionValues.MY_ROLE_HOME || values['my_role'] === optionValues.MY_ROLE_STUDENT) {
        return rulesHomeOrStudent;
    }

    return rulesEducator;
};

const validation = createValidation<FormValues>(rulesCallback);

export default validation;
